import React, { useState } from 'react';

import styles from './Form.module.sass'

const TextArea = ({value, onChange}) => {
    const [ data, setData ] = useState({
        value: '',
        rows: 1,
        minRows: 1,
        maxRows: 10
    })

    const handleChange = (event) => {
		const textareaLineHeight = 24;
		const { minRows, maxRows } = data;
		
		const previousRows = event.target.rows;
  	    event.target.rows = minRows; // reset number of rows in textarea 
		
		const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);
    
        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }
		
		if (currentRows >= maxRows) {
			event.target.rows = maxRows;
			event.target.scrollTop = event.target.scrollHeight;
		}
        
        onChange(event.target.value)

        setData({...data,
            rows: currentRows < maxRows ? currentRows : maxRows,
        });
	};
	
	
		return (
			<textarea 
				rows={data.rows}
				value={value}
				placeholder={'Your message'}
				className={styles.textarea}
				onChange={handleChange}
			/>
		);
	
};

const Checkbox = ({checked, onClick, children}) => {

    return (
        <div className={'checkbox' + (checked ? ' active': '')} onClick={() => onClick()}>
            <span className="icon" />
            {children}
        </div>
    )
}

const FileUpload = ({type, onChange, onCancel, placeHolder, value, withPreview, errors}) => {
    const [ fileData, setFileData ] = useState(null)

    const selectFile = (e) => {
        e.preventDefault()

        console.log('selectFile')

	    let reader = new FileReader()
	    let file = e.target.files[0]

        let filename = file.name;

        console.log(file)

	    reader.onloadend = () => {
	      /*
	      this.setState({
	        file: file,
	        imagePreviewUrl: reader.result
	      })
	      */
          //this.props.onSaveImage(reader.result)

          onChange({
                type: type,
                name: filename,
                base64: reader.result
          })

          setFileData({
                type: type,
                name: filename,
                base64: reader.result
        });

	      console.log(reader.result)
	    }

	    reader.readAsDataURL(file)
    }

    const cancelFile = () => {
        setFileData(null);
        onChange({
            type: type,
        })
    }

    console.log(fileData)

    let active = false
    let fileName = null
    let style = null

    if(fileData) {
        active = true
        fileName = fileData.name
        style = {backgroundImage: `url(${fileData.base64})`}
    }

    if(value) {
        active = true
        fileName = <a href={value.url} target="_blank">{value.filename}</a>
        style = {backgroundImage: `url(${value.url})`}
    }

    console.log(value)

    let classes = ['file']
    if(withPreview) classes.push('withPreview')
    if(errors && errors.findIndex(error => error.name === type)>-1 && !fileName) classes.push('error')

    return (
        <div className={classes.join(' ')}>
           
            {withPreview &&
                <div className="preview" style={style}></div>
            }

            {(active) &&
                <>
                <span className="name">{fileName}</span>
                   <span className="remove" onClick={() => cancelFile()}>Löschen</span>
                </>
            }

            {(!active) &&
                <>
                    <span className="name">{placeHolder}</span>
                    
                    <span className="select" onClick={() => document.getElementById('file' + type).click()}>Choose file</span>
                </>
            }
            <input type="file" id={'file' + type} onChange={selectFile} />
        </div>
    );
}

export { TextArea, FileUpload, Checkbox }