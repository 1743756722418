import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'
import { bookmarkConstants } from '../constants';


export function* handleBookmark(payload) {
    console.log('>>>')
    console.log(payload)
    
    try {

        //let model = {ID: 119}

        yield put({ type: bookmarkConstants.ADDREMOVETOCASTING_SUCCESS, id: payload.id  })
    } catch (error) {
        yield console.log('ERROR')
        //yield put(setError(error.toString()));
    }
}

export function* handlePdf(payload) {
    console.log('>>>')
    console.log(payload)
    
    try {

        //let model = {ID: 119}

        yield put({ type: bookmarkConstants.ADDREMOVETOPDF_SUCCESS, idModel: payload.idModel, idImage: payload.idImage  })
    } catch (error) {
        yield console.log('ERROR')
        //yield put(setError(error.toString()));
    }
}

export default function* watchEventLoad() {
    yield takeEvery(bookmarkConstants.ADDREMOVETOCASTING_REQUEST, handleBookmark);
    yield takeEvery(bookmarkConstants.ADDREMOVETOPDF_REQUEST, handlePdf);
}