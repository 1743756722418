import React from 'react'

const Icon = ({type}) => {
    switch (type) {
        case 'arrow':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.8 36.77">
                    <polyline points="0.71 0.71 18.39 18.39 0.71 36.06" fill="none" stroke="#fff" strokeWidth="2"/>
                </svg>

            )

        case 'instagram':
            return (
                <svg width="29" height="29" viewBox="0 0 28.99 28.99">
                    <path d="M16.28.5c2.79,0,3.33,0,4.69.09a10.61,10.61,0,0,1,3.52.67A7.38,7.38,0,0,1,28.73,5.5,10.61,10.61,0,0,1,29.4,9c.07,1.36.09,1.9.09,4.69v2.57c0,2.79,0,3.33-.09,4.69a10.61,10.61,0,0,1-.67,3.52,7.38,7.38,0,0,1-4.24,4.24A10.61,10.61,0,0,1,21,29.4c-1.36.07-1.9.09-4.69.09H13.71c-2.79,0-3.33,0-4.69-.09a10.61,10.61,0,0,1-3.52-.67,7.38,7.38,0,0,1-4.24-4.24A10.61,10.61,0,0,1,.59,21C.52,19.61.5,19.07.5,16.28V13.71c0-2.79,0-3.33.09-4.69A10.61,10.61,0,0,1,1.26,5.5,7.38,7.38,0,0,1,5.5,1.26,10.61,10.61,0,0,1,9,.59C10.38.52,10.92.5,13.71.5Zm-.62,2.61H14.33c-3.26,0-3.76,0-5.19.09a8.14,8.14,0,0,0-2.69.49A4.57,4.57,0,0,0,4.78,4.78,4.57,4.57,0,0,0,3.69,6.45,8.14,8.14,0,0,0,3.2,9.14c-.07,1.43-.09,1.93-.09,5.19v1.33c0,3.26,0,3.76.09,5.19a8.14,8.14,0,0,0,.49,2.69,4.57,4.57,0,0,0,1.09,1.67,4.42,4.42,0,0,0,1.67,1.08,7.81,7.81,0,0,0,2.69.5c1.53.07,2,.09,5.85.09h.35c3.55,0,4,0,5.51-.09a7.81,7.81,0,0,0,2.69-.5,4.74,4.74,0,0,0,2.75-2.75,7.81,7.81,0,0,0,.5-2.69c.07-1.53.09-2,.09-5.86v-.34c0-3.55,0-4-.09-5.51a7.81,7.81,0,0,0-.5-2.69,4.42,4.42,0,0,0-1.08-1.67,4.57,4.57,0,0,0-1.67-1.09,8.14,8.14,0,0,0-2.69-.49C19.42,3.13,18.92,3.11,15.66,3.11ZM15,7.55A7.45,7.45,0,1,1,7.55,15,7.44,7.44,0,0,1,15,7.55Zm0,2.61A4.84,4.84,0,1,1,10.16,15,4.84,4.84,0,0,1,15,10.16Zm7.74-4.64A1.74,1.74,0,1,1,21,7.26,1.74,1.74,0,0,1,22.73,5.52Z" transform="translate(-0.5 -0.5)" fillRule="evenodd"/>
                </svg>
            )
        
        case 'models':
            return (
                <svg width="71" height="29" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.91 15.98">
                    <path d="M16.25,15.65H13.3v-7a2.65,2.65,0,0,0-.55-1.78,1.44,1.44,0,0,0-1.19-.59,1.71,1.71,0,0,0-1.33.63,2.74,2.74,0,0,0-.59,1.83v6.91h-3v-7A3.58,3.58,0,0,0,6.35,7a1.61,1.61,0,0,0-1.51-.68C3.62,6.34,3,7.1,3,8.61v7H0V4.06H2.84V5.17a3,3,0,0,0,0,.63,0,0,0,0,1,0,0,4.92,4.92,0,0,1,1.34-1.5,3.39,3.39,0,0,1,1.92-.5A3.57,3.57,0,0,1,8,4.18,4.19,4.19,0,0,1,9.3,5.63,5,5,0,0,1,10.63,4.2a3,3,0,0,1,1.74-.49,4.15,4.15,0,0,1,2.08.57,3.12,3.12,0,0,1,1.39,1.47,4.51,4.51,0,0,1,.41,1.88v8" transform="translate(0 -0.02)" />
                    <path d="M25.59,9.87q0-3.52-2.73-3.53c-1.84,0-2.75,1.18-2.75,3.53s.91,3.52,2.75,3.52S25.59,12.22,25.59,9.87Zm3,0a6.19,6.19,0,0,1-1.55,4.42A5.51,5.51,0,0,1,22.86,16a5.58,5.58,0,0,1-4.28-1.69A6.22,6.22,0,0,1,17,9.87a6.26,6.26,0,0,1,1.56-4.43,5.57,5.57,0,0,1,4.29-1.68,5.49,5.49,0,0,1,4.28,1.71,6.34,6.34,0,0,1,1.49,4.4" transform="translate(0 -0.02)" />
                    <path d="M37.35,10.13c0-2.5-.84-3.74-2.53-3.74S32.26,7.58,32.26,10a4.11,4.11,0,0,0,.79,2.66,2.2,2.2,0,0,0,3.49,0A3.69,3.69,0,0,0,37.35,10.13Zm2.88,5.52H37.31V14.22h0A3.43,3.43,0,0,1,34.14,16a4.54,4.54,0,0,1-2.67-.86,4.85,4.85,0,0,1-1.75-2.21,8.7,8.7,0,0,1-.53-3.17A7.14,7.14,0,0,1,30.3,5.65a4.07,4.07,0,0,1,3.6-1.89,3.71,3.71,0,0,1,3.28,1.82h0V0h3V15.65" transform="translate(0 -0.02)" />
                    <path d="M48.68,8.74A2.91,2.91,0,0,0,47.9,6.9a2.16,2.16,0,0,0-1.55-.66,2,2,0,0,0-1.47.62A3.17,3.17,0,0,0,44,8.74Zm3.07,1.93H43.92a3.33,3.33,0,0,0,.9,2.22,2.33,2.33,0,0,0,1.66.65,2.39,2.39,0,0,0,1.3-.35,1.63,1.63,0,0,0,.75-.93H51.6A5,5,0,0,1,49.76,15a5.18,5.18,0,0,1-3.13,1q-5.7,0-5.69-6.33a6.16,6.16,0,0,1,1.44-4.28,5.15,5.15,0,0,1,4-1.59q5.32,0,5.33,6.91" transform="translate(0 -0.02)" />
                    <polyline points="55.6 15.64 52.6 15.64 52.6 0 55.6 0 55.6 15.64" />
                    <path d="M66.91,12a3.85,3.85,0,0,1-.6,2.16Q65,16,61.87,16a6.62,6.62,0,0,1-3.69-1A3.54,3.54,0,0,1,56.46,12h3.09a1.51,1.51,0,0,0,.72,1.33,2.84,2.84,0,0,0,1.58.41,3,3,0,0,0,1.44-.33,1,1,0,0,0,.61-.88c0-.48-.61-.86-1.81-1.13a20.82,20.82,0,0,1-3.39-1,2.91,2.91,0,0,1-1.82-2.74,3.8,3.8,0,0,1,.52-2,3.51,3.51,0,0,1,1.72-1.43,6.57,6.57,0,0,1,2.63-.53A5.3,5.3,0,0,1,65,4.63a3.64,3.64,0,0,1,1.6,3h-3A1.74,1.74,0,0,0,61.6,6a2.32,2.32,0,0,0-1.26.33.93.93,0,0,0-.53.82c0,.44.61.79,1.83,1.07a19.31,19.31,0,0,1,3.43,1A2.91,2.91,0,0,1,66.91,12" transform="translate(0 -0.02)" />
                    <polyline points="70.91 15.59 67.69 15.59 67.69 12.42 70.91 12.42 70.91 15.59" />
                </svg>
            )

        case 'mute':

            return (        
                <svg width="39px" height="35px" viewBox="0 0 39 35">
                
                    <g id="MINT-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="01-Home-one-Kontur" transform="translate(-1298.000000, -978.000000)">
                            <g id="Sound_OFF" transform="translate(1294.000000, 973.000000)">
                                <rect id="Rectangle" fill="#FFFFFF" opacity="0" x="0" y="0" width="46" height="46"></rect>
                                <path d="M24.4444444,36.1476607 L14.2222222,28.1479944 L14.2222222,16.8534656 L24.4444444,8.85379927 L24.4444444,36.1476607 Z M6.55555556,27.5005214 L11.6666667,27.5005214 L11.6666667,17.5009386 L6.55555556,17.5009386 L6.55555556,27.5005214 Z M26.2767778,5.12395486 C25.8321111,4.91646351 25.3082222,4.97146122 24.9248889,5.2739486 L12.4946667,15.0010428 L6.55555556,15.0010428 C5.14744444,15.0010428 4,16.123496 4,17.5009386 L4,27.5005214 C4,28.8778858 5.14744444,30.0004171 6.55555556,30.0004171 L12.4946667,30.0004171 L24.9223333,39.7275114 C25.1548889,39.9075039 25.4385556,40 25.7222222,40 C25.9113333,40 26.1004444,39.9600017 26.2767778,39.8775051 C26.7188889,39.6675139 27,39.2300321 27,38.7500521 L27,6.25140783 C27,5.77142785 26.7188889,5.3339461 26.2767778,5.12395486 L26.2767778,5.12395486 Z" id="Fill-1" fill="#F4FF00"></path>
                                <g id="off" transform="translate(31.000000, 17.000000)" stroke="#F4FF00" stroke-linecap="square" stroke-width="2.5">
                                    <line x1="0.147058824" y1="9.85294118" x2="9.85294118" y2="0.147058824" id="Line"></line>
                                    <line x1="0.147058824" y1="9.85294118" x2="9.85294118" y2="0.147058824" id="Line" transform="translate(5.000000, 5.000000) scale(-1, 1) translate(-5.000000, -5.000000) "></line>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            )

        
        case 'muted':

            return (
                <svg width="39px" height="35px" viewBox="1 0 38 35">

                    <g id="MINT-Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="01-Home-one-Kontur" transform="translate(-1362.000000, -978.000000)">
                            <g id="Sound_ON" transform="translate(1358.000000, 973.000000)">
                                <rect id="Rectangle" fill="#FFFFFF" opacity="0" x="0" y="0" width="46" height="46"></rect>
                                <path d="M24.4444444,36.1476607 L14.2222222,28.1479944 L14.2222222,16.8534656 L24.4444444,8.85379927 L24.4444444,36.1476607 Z M6.55555556,27.5005214 L11.6666667,27.5005214 L11.6666667,17.5009386 L6.55555556,17.5009386 L6.55555556,27.5005214 Z M26.2767778,5.12395486 C25.8321111,4.91646351 25.3082222,4.97146122 24.9248889,5.2739486 L12.4946667,15.0010428 L6.55555556,15.0010428 C5.14744444,15.0010428 4,16.123496 4,17.5009386 L4,27.5005214 C4,28.8778858 5.14744444,30.0004171 6.55555556,30.0004171 L12.4946667,30.0004171 L24.9223333,39.7275114 C25.1548889,39.9075039 25.4385556,40 25.7222222,40 C25.9113333,40 26.1004444,39.9600017 26.2767778,39.8775051 C26.7188889,39.6675139 27,39.2300321 27,38.7500521 L27,6.25140783 C27,5.77142785 26.7188889,5.3339461 26.2767778,5.12395486 L26.2767778,5.12395486 Z" id="Fill-1" fill="#F4FF00"></path>
                                <path d="M33.2034814,14.3537006 C32.6939223,13.8768397 31.8767688,13.884176 31.3750491,14.3634823 C30.8733293,14.8452341 30.8784209,15.6179934 31.3853938,16.0924088 C33.3378822,17.919153 34.4138157,20.3719299 34.4138157,23.0007786 C34.4138157,25.6296272 33.3378822,28.0824041 31.3853938,29.9091483 C30.8758347,30.3835638 30.8707431,31.156323 31.3750491,31.6380748 C31.6284951,31.8801734 31.9621129,32 32.2930637,32 C32.6215899,32 32.9499545,31.8826189 33.2034814,31.6454111 C35.652598,29.3589241 37,26.2898962 37,23.0007786 C37,19.7116609 35.652598,16.642633 33.2034814,14.3537006" id="Fill-4" fill="#F4FF00"></path>
                                <path d="M37.0490392,11.3554945 C36.5775943,10.8790586 35.8149827,10.8815144 35.3481989,11.362862 C34.8815654,11.8442096 34.8838959,12.6227157 35.3554912,13.0991516 C38.0883079,15.8669002 39.5942853,19.5605062 39.5942853,23.4996977 C39.5942853,27.4388893 38.0883079,31.1324953 35.3554912,33.9002439 C34.8838959,34.3766798 34.8815654,35.1551859 35.3481989,35.6365335 C35.5840341,35.8796631 35.8918904,36 36.2022276,36 C36.5078285,36 36.8132791,35.8820422 37.0490392,35.643901 C40.2414977,32.4119958 42,28.0995143 42,23.4996977 C42,18.8998812 40.2414977,14.5873997 37.0490392,11.3554945" id="Fill-6" fill="#F4FF00"></path>
                            </g>
                        </g>
                    </g>
                </svg>
            )

                
    }
}

export default Icon;