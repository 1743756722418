import { all } from 'redux-saga/effects';

import config from './config';
import pages from './pages';
import bookmarks from './bookmarks';

console.log('allSagas')

export default function* rootSaga() {
    yield all([config(), pages(), bookmarks()]);
}