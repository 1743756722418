import React from 'react';
import styles from './Footer.module.sass'

import Icon from 'components/Icon'
import { NavLink } from 'react-router-dom';

const Index = () => {
    return (
        <div id={styles.footer}>
            <div className="center">
                <div className="left">
                    <div>
                        <span className="border"></span>
                        <div className="social">
                            <h6>Follow us on:</h6>
                            <ul>
                                <li><a href="https://www.instagram.com/mintartistmanagement" target="_blank"><Icon type="instagram" /></a></li>
                                <li><a href="https://models.com/agency/mint-artist-management" target="_blank"><Icon type="models" /></a></li>
                            </ul>
                        </div>
                        <ul className="actions">
                            <li><NavLink to="/terms-conditions/">Terms &amp; Conditions</NavLink></li>
                            <li><NavLink to="/privacy-policy/">Privacy Policy</NavLink></li>
                            <li><NavLink to="/imprint/">Imprint</NavLink></li>
                        </ul>
                        <p>© 2020 MINT. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;