import { bookmarkConstants } from '../constants'
import moment from 'moment'

let casting = JSON.parse(localStorage.getItem('casting'));
let pdf = JSON.parse(localStorage.getItem('pdf'));

if(casting) {
    let index = casting.findIndex(item => item.ID)

    if(index>-1) {
        casting = []
        localStorage.setItem('casting', JSON.stringify(casting));
    }
}

const initialState = {
    casting: casting ? casting : [],
    pdf: pdf ? pdf : [],
}

export function bookmarks(state = initialState, action) {
    console.log(action.type)
    switch (action.type) {
        case bookmarkConstants.ADDREMOVETOCASTING_SUCCESS:

            console.log(action.id)

            let casting = [...state.casting]
            const index = casting.findIndex(item => item === action.id)
    
 
            if(index>-1) {
                casting.splice(index, 1)
            } else {
    
               
    
                casting.push(action.id)
            }

            console.log(casting)

            localStorage.setItem('casting', JSON.stringify(casting));

            return {
                ...state,
                casting
            }
        
        case bookmarkConstants.ADDREMOVETOPDF_SUCCESS:

            console.log('idModel:', action.idModel, 'idImage:', action.idImage)

            let pdf = [...state.pdf]
            const indexPdf = pdf.findIndex(item => item.idModel === action.idModel && item.idImage === action.idImage)
    
 
            if(indexPdf>-1) {
                pdf.splice(indexPdf, 1)
            } else {
    
               
    
                pdf.push({idModel:  action.idModel, idImage:  action.idImage })
            }

            console.log(pdf)

            localStorage.setItem('pdf', JSON.stringify(pdf));

            return {
                ...state,
                pdf
            }

        default:
            return state
    }
}