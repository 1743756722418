import React from 'react';
import styles from './Signup.module.sass'
import stylesForm from 'components/page/parts/form/Form.module.sass'

import { Link } from 'react-router-dom'

import { useState, useEffect } from 'react';

import LazyImage from 'components/page/parts/lazyImage'

import { TextArea, FileUpload, Checkbox } from 'components/page/parts/form'




const Index = ({ data,style }) => {
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        text: '',
        privacy: false
    })

    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    const onChangeFile = (props) => {
        const newData = { ...formData }

        newData[props.type] = null

        let files = newData['files'] ? newData['files'] : []

        const index = files.findIndex(file => file.type === props.type)

        if (index > -1) {
            files[index] = props
        } else {
            files.push(props)
        }

        if (!props.base64) {
            files = files.filter(file => file.type !== props.type)
        }

        newData['files'] = files

        setFormData(formData)
    }

    const handleSubmit = () => {

        if(isLoading || !formData.privacy || !formData.email || !formData.name) {
            return
        }

        setIsLoading('loading');

        let target = 'http://backend.mintartistmanagement.world/api/signup.php'
        const data = new FormData();

        for(var i=1; i<=4; i++) {

            const myFile = document.getElementById('fileimage' + i).files[0];

        
            data.append('file' + i, myFile);

        }

        data.append('email', formData.email);
        data.append('name', formData.name);
        data.append('message', formData.text);
        
        fetch(target, {
            method: "POST",
            body: data
        })
        .then(response => response.json())
        .then(data => {
            setIsLoading('loaded');
            console.log('finished')
        });
    }

    console.log(data)

    let image = data.content.image

    return (
        <div className={styles.signup + ' center'}>
            <div className="left">
                <div className="form">
                    <span className="border">
                        <h2 dangerouslySetInnerHTML={{ __html: data.title.toLowerCase() }}  style={style.borderTitle}></h2>
                    </span>
                    <div>
                        <div className={stylesForm.form}>
                            <div>
                                <input type="text" value={formData.email} placeholder="Your Email" onChange={e => handleChange('email', e.target.value)} />
                                <input type="text" value={formData.name} placeholder="Your Name" onChange={e => handleChange('name', e.target.value)} />
                                <TextArea value={formData.text} onChange={value => handleChange('text', value)} />
                                <Checkbox 
                                    checked={formData.privacy} 
                                    onClick={() => handleChange('privacy', !formData.privacy)}
                                >
                                    By submitting the application form, you agree to your data being used to process your request (for further information and revocation instructions, please refer to the <Link to="/privacy-policy/">privacy policy</Link>).
                                </Checkbox>
                                <span className="submit" onClick={() => handleSubmit()}>SEND</span>
                                {isLoading==='loading' &&
                                    <p className="info">Sending data...</p>
                                }
                                {isLoading==='loaded' &&
                                    <p className="info">Sending data finished</p>
                                }
                            </div>
                            <div>
                                <div>
                                    <FileUpload
                                        type="image1"
                                        onChange={props => onChangeFile(props)}
                                        value={formData.image1}
                                        placeHolder="Portrait Shot *"
                                    />
                                    <FileUpload
                                        type="image2"
                                        onChange={props => onChangeFile(props)}
                                        value={formData.image2}
                                        placeHolder="Full Size Image"
                                    />
                                    <FileUpload
                                        type="image3"
                                        onChange={props => onChangeFile(props)}
                                        value={formData.image1}
                                        placeHolder="Polaroid 1"
                                    />
                                    <FileUpload
                                        type="image4"
                                        onChange={props => onChangeFile(props)}
                                        value={formData.image4}
                                        placeHolder="Polaroid 2"
                                    />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;