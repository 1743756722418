import React, { useRef, useEffect, useState } from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux'
import styles from './Model.module.sass'
import { setMeasurements, addRemoveToCasting, addRemoveImageToPdf } from 'actions'

import Images from './Images'

import Overlay from 'components/page/parts/overlay'

import getVideoId from 'get-video-id';

import Icon from 'components/Icon'

const Index = ({ data, measurementsCountry, setMeasurements, location, addRemoveToCasting, addRemoveImageToPdf, bookmarked, style, pdf, cookie }) => {

    //console.log(data)

    const imageRef = useRef(null)
    const textRef = useRef(null)

    const [textStyle, setTextStyle] = useState(null)
    const [textOpen, setTextOpen] = useState(false)

    const animateText = useRef(null)

    useEffect(() => {
        animateText.current = false

        if(imageRef.current) {
            const paddingTop = style.contentWidth * 0.147;
            console.log(paddingTop)

            if(window.innerWidth > 767) {

                if (imageRef.current.clientHeight < textRef.current.clientHeight + paddingTop) {

                    setTextStyle({
                        height: (Math.floor((imageRef.current.clientHeight - paddingTop) / 28) * 28) - 28
                    })

                }

            } else {
                setTextStyle({
                    height: 100
                })
            }
        }

    }, [style])

    let image = data.content.image
    let styleImage

    //let model = {, category: data.content.category, name: data.content.name}

    if (image) {
        styleImage = {
            paddingBottom: `${image.height / image.width * 100}%`,
            backgroundImage: `url(${image.path})`
        }

        //model.image = image;


    }

    const bookmarkImage = (ID) => {
        addRemoveImageToPdf(data.ID, ID);
    }

    const toggleText = () => {
        animateText.current = true
        setTextOpen(!textOpen)
        //animateText.current = false
    }

    const createPdf = () => {

        let imageIDs = pdf.map(item => item.idImage);



        const form = document.createElement('form');
        form.method = 'get';
        form.action = 'http://backend.mintartistmanagement.world/wp-content/themes/mint/php/pdf.php';

        //?m=' + data.ID + '&i' + imageIDs.join(',')

        const input1 = document.createElement('input');
        input1.name = 'm'
        input1.value = data.ID

        form.appendChild(input1);

        const input2 = document.createElement('input');
        input2.name = 'i'
        input2.value = imageIDs.join(',')

        form.appendChild(input2);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }


    const measurementsFields = [
        { label: 'height', name: 'height_' + measurementsCountry, unit: 'cm' },
        { label: 'bust', name: 'bust_' + measurementsCountry, unit: 'cm' },
        { label: 'waist', name: 'waist_' + measurementsCountry, unit: 'cm' },
        { label: 'hips', name: 'hips_' + measurementsCountry, unit: 'cm' },
        { label: 'shoes', name: 'shoes_' + measurementsCountry },
        { label: 'hair', name: 'hair' },
        { label: 'eyes', name: 'eyes' }
    ]

    let measurementsTemp = data.content.measurements;

    //console.log(measurementsTemp)

    const measurements = measurementsFields.map((item, i) => {

        if (measurementsTemp && measurementsTemp[item.name]) {

            let value = measurementsTemp[item.name]

            if (item.unit && measurementsCountry === 'eu') {
                value = value + ' ' + item.unit
            }

            //console.log('found')
            return <li key={i}><strong>{item.label}</strong> {value}</li>
        }



    })

    let params = new URLSearchParams(location.search)

    let images = []

    let imageType = location.hash === '' ? 'images' : 'polaroids'



    if (data.content[imageType]) {
        data.content[imageType].forEach(row => {
            row.forEach(item => {
                images.push(item)
            })
        })
    }

    let videos = []

    if (location.hash === '#videos') {

        console.log(data.content.videos)

        data.content.videos.forEach(row => {

            videos.push(
                <div>
                    {row.map((item, i) => {

                        const { id } = getVideoId(item);

                        return (
                            <div>
                                <div>
                                    {cookie &&
                                        <iframe
                                            width="560"
                                            height="315"
                                            src={`https://www.youtube.com/embed/${id}`} 
                                            frameborder="0" 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen />
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
        })
    }

    return (
        <div className={styles.model + ' center'}>
            <div className="left">
                <div className="header">
                    <span className="border"><h1 style={style.borderTitle}>{data.title}</h1></span>
                    <div>
                        <div>
                            {image &&
                                <div className="image" style={styleImage} ref={imageRef} />
                            }
                        </div>
                        <div>
                            <span className={'casting' + (bookmarked ? ' active' : '')} onClick={() => addRemoveToCasting(data.ID)}>{!bookmarked ? 'Add to casting' : 'remove from casting'}<span></span></span>
                            {data.content.instagram &&
                                <a href={`https://www.instagram.com/${data.content.instagram}/`} className="instagram" target="_blank"><Icon type="instagram" /></a>
                            }
                            <div className={'text' + (animateText.current ? ' animate' : '')} style={!textOpen ? textStyle : {height: textRef.current.clientHeight + 'px'}}>
                                <div className="rich" dangerouslySetInnerHTML={{ __html: data.content.description }} ref={textRef} />
                                {(textStyle && !textOpen) &&
                                    <span></span>
                                }
                            </div>
                            {textStyle &&
                                <span className="more" onClick={() => toggleText()}>{!textOpen ? 'more' : 'less'}</span>
                            }
                        </div>
                        <div>
                            <ul className="actions">
                                <li><span onClick={() => setMeasurements('eu')} className={measurementsCountry === 'eu' ? 'active' : ''}>EU</span></li>
                                <li><span onClick={() => setMeasurements('us')} className={measurementsCountry === 'us' ? 'active' : ''}>US</span></li>
                            </ul>
                            <ul>{measurements}</ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="images">
                    <div>
                        <ul className="actions">
                            <li><NavLink to={{ ...location, hash: null }} isActive={() => location.hash === ''}>Images</NavLink></li>
                            {data.content.polaroids &&
                                <li><NavLink to={{ ...location, hash: 'polaroids' }} isActive={() => location.hash === '#polaroids'}>Polaroids</NavLink></li>
                            }
                            {data.content.videos &&
                                <li><NavLink to={{ ...location, hash: 'videos' }} isActive={() => location.hash === '#videos'}>Videos</NavLink></li>
                            }
                        </ul>
                        <ul className="actions">
                            <li><span onClick={() => createPdf()}>Select images and Generate PDF</span></li>
                            {data.content.presskit && 
                                <li><a href={data.content.presskit} download={data.content.presskit.replace(/^.*[\\\/]/, '')}>Download Press Kit</a></li>
                            }
                        </ul>
                    </div>
                    {location.hash === '' &&
                        <div>
                            <span className="border"><h2 style={style.borderTitle}>Images</h2></span>
                            <Images data={data.content.images} style={style} onBookMark={(ID) => bookmarkImage(ID)} pdf={pdf} />
                        </div>
                    }
                    {location.hash === '#polaroids' &&
                        <div>
                            <span className="border"><h2 style={style.borderTitle} >Polaroids</h2></span>
                            <Images data={data.content.polaroids} style={style} onBookMark={(ID) => bookmarkImage(ID)} pdf={pdf} />
                        </div>
                    }
                    {location.hash === '#videos' &&
                        <div className="videos">
                            <span className="border"><h2 style={style.borderTitle}>Videos</h2></span>
                            {videos}
                        </div>
                    }
                </div>
            </div>
            {(images.length > 0) &&
                <Overlay
                    data={images}
                    active={!!params.get('i')} />
            }
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const { measurements, cookie } = state.config
    const { casting, pdf } = state.bookmarks

    console.log(casting)

    const bookmarked = casting.find(item => item === props.data.ID)

    const pdfImages = pdf.filter(item => item.idModel === props.data.ID)
    console.log('>>>')
    console.log(pdfImages)

    return {
        measurementsCountry: measurements,
        cookie,
        bookmarked,
        pdf: pdfImages
    }
}

const mapDispatchToProps = dispatch => ({
    setMeasurements: (country) => dispatch(setMeasurements(country)),
    addRemoveToCasting: (id) => dispatch(addRemoveToCasting(id)),
    addRemoveImageToPdf: (idModel, idImage) => dispatch(addRemoveImageToPdf(idModel, idImage))
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index))
