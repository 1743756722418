import React from 'react';
import styles from './Contact.module.sass'

import { Link } from 'react-router-dom'

import stylesForm from 'components/page/parts/form/Form.module.sass'

import Slider from 'components/page/parts/slider'
import { useState, useEffect } from 'react';

import LazyImage from 'components/page/parts/lazyImage'

import { TextArea, Checkbox } from 'components/page/parts/form'

import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>
    <svg width="46px" height="60px" viewBox="0 0 46 60" version="1.1">
        <g id="MINT-Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="07-Contact" transform="translate(-710.000000, -289.000000)" fill="#000000" fillRule="nonzero">
                <g id="map-locator" transform="translate(710.000000, 289.000000)">
                    <path d="M22.9999175,0 C10.3178927,0 0,10.3551753 0,23.0831907 C0,35.1139939 19.0904447,55.8263318 21.2669577,58.1513111 L22.9999175,60 L24.7328773,58.1513111 C26.9098853,55.8283191 46,35.1139939 46,23.0831907 C46.0009901,10.3551753 35.6830974,0 22.9999175,0 Z M23,32.6086957 C17.8201984,32.6086957 13.6056338,28.3662465 13.6056338,23.1521739 C13.6056338,17.9379374 17.8201984,13.6956522 23,13.6956522 C28.1809418,13.6956522 32.3943662,17.9381014 32.3943662,23.1521739 C32.3943662,28.3664104 28.1809418,32.6086957 23,32.6086957 Z" id="Icon_map-location"></path>
                </g>
            </g>
        </g>
    </svg>
</div>;

const Index = ({ data, style }) => {
    let defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        text: '',
        privacy: false
    })

    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = () => {

        if(isLoading || !formData.privacy || !formData.email || !formData.name || !formData.text) {
            return
        }

        setIsLoading('loading');

        let target = 'http://backend.mintartistmanagement.world/api/contact.php'
        const data = new FormData();

        data.append('email', formData.email);
        data.append('name', formData.name);
        data.append('message', formData.text);
        
        fetch(target, {
            method: "POST",
            body: data
        })
        .then(response => response.json())
        .then(data => {
            setIsLoading('loaded');
            console.log('finished')
        });
    }

    console.log(data)

    let image = data.content.image

    //AIzaSyAPTR_xsc9jF8K8_MccZyBrjDrvM31XaX0

    //AIzaSyBfNwoZmjLuiWbHAHNRSFODB0sRcb4Xc5M

    return (
        <div className={styles.contact + ' center'}>
            <div className="left">
                <div className="text">
                    <span className="border">
                        <h2 dangerouslySetInnerHTML={{ __html: data.title.toLowerCase() }} style={style.borderTitle}></h2>
                    </span>
                    <div>
                        <div>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyBfNwoZmjLuiWbHAHNRSFODB0sRcb4Xc5M' }}
                                defaultCenter={defaultProps.center}
                                defaultZoom={defaultProps.zoom}
                            >
                                <AnyReactComponent
                                    lat={52.5361124}
                                    lng={13.4212735}
                                    
                                />
                            </GoogleMapReact>
                        </div>
                        <div>
                            <div className="rich" dangerouslySetInnerHTML={{ __html: data.content.text }}></div>
                        </div>
                        <div className={stylesForm.form}>
                            <div>

                                <input type="text" value={formData.email} placeholder="Your Email" onChange={e => handleChange('email', e.target.value)} />

                                <input type="text" value={formData.name} placeholder="Your Name" onChange={e => handleChange('name', e.target.value)} />

                                <TextArea value={formData.text} onChange={value => handleChange('text', value)} />
                                <Checkbox
                                    checked={formData.privacy}
                                    onClick={() => handleChange('privacy', !formData.privacy)}
                                >
                                    By submitting the contact form, you agree to your data being used to process your request (for further information and revocation instructions, please refer to the <Link to="/privacy-policy/">privacy policy</Link>).
                                </Checkbox>
                                <span className="submit" onClick={() => handleSubmit()}>SEND</span>
                                {isLoading==='loading' &&
                                    <p className="info">Sending data...</p>
                                }
                                {isLoading==='loaded' &&
                                    <p className="info">Sending data finished</p>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;