import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom'
import styles from './Slider.module.sass'

import Icon from 'components/Icon'

const Index = ({data, location, style}) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const [touching, setTouching] = useState({
        isTouching: false,
        touchXstart: null,
        touchX: null
    })

    let slides = []

   const  columns =  (style.contentWidth+30)>800 ? 3 : (style.contentWidth+30)>400 ? 2 : 1

    const getImage = (slide, image) => {
        
        //console.log('-----------')
        //console.log(slide, image)
       
        
        let test = slide*columns+image

        let test2 = test%data.length;

        //console.log(test2)

        if(test2<0) {
            test2 = data.length+test2
        }

        //console.log(test2)

       
        //console.log(data[test2])

      

        return test2

    }

    for(let i = currentSlide-2; i<=currentSlide+2; i++) {
        let left = i*100

        let models = []

        for(let j=0; j<columns; j++) {
            
            let item = data[ getImage(i, j) ]
            

            models.push(
                <li key={item.ID}>
                        <div>
                            <Link 
                                className="clickArea"
                                to={`/${item.category}/${item.name}`} />
                            <div
                                className="image"
                                style={item.image ? {backgroundImage: `url(${item.image.path})`} : null} />
                            <h3>{item.title}</h3>
                        </div>
                </li>
            )

        }

        
        slides.push(
            <div key={i} style={{left: `${left}%`}}>
                <ul>
                    {models}
                </ul>
            </div>
        )
    }


    return (
        <div className={styles.slider + ' columns' + columns }>
            <span className="border right">
                <h2 style={style.borderTitle}>featured</h2>
            </span>
            <div>
                <div>
                    <div className="slides">
                        <div style={{transform: `translateX(${-currentSlide*100}%)`}}>
                            {slides}
                        </div>
                    </div>
                    <span className="button previous" onClick={() => setCurrentSlide(currentSlide-1)}>
                        <Icon type="arrow" />
                    </span>
                    <span className="button next"  onClick={() => setCurrentSlide(currentSlide+1)}>
                        <Icon type="arrow" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Index);