import React, { useRef, useMemo, useEffect, useState } from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import { usePreload } from 'hooks'
import LazyImage from 'components/page/parts/lazyImage'

const Image = ({style, image, onClick, onBookMark, inPdf}) => {
    //const ref = useRef()
    //const { loaded } = usePreload({ref, image})
    
    return (
        <div style={style}>
            
            <LazyImage
                image={image}
                onClick={() => onClick()}
                />
            <span onClick={() => onBookMark()} className={'add' + (inPdf ? ' active' : '')}></span>
        </div>
    )
}
 
const Images = ({data, history, location, style, onBookMark, pdf}) => {
    const ref = useRef(null)

    //console.log(data)

    let [ testY, setTestY ] = useState(0)

    useEffect(() => {
        if(ref.current) {
            setTestY(ref.current.getBoundingClientRect().y + window.scrollY)
            //console.log('>>' + component.current.getBoundingClientRect().y + window.scrollY)
        }
    });

    const html = useMemo(
        () => {
            
            const mobile = window.innerWidth < 600

            //console.log(data)

            const clientWidth = style.contentWidth
            const spaceVertical = clientWidth * 0.03
            //const spaceHorizontal = clientWidth * 0.067
            const spaceHorizontal = clientWidth * 0.03
            const groups = data
            const content = []
            let height

            //const addY = ref.current.getBoundingClientRect().y + window.scrollY
            //console.log('render with: ' + addY)

            //const spaceVertical = 10
            //const spaceHorizontal = 10

            if(!data) return

                let top = 0

                data.forEach((group, i) => {

                    const test = group.reduce((acc, image) => (

                        acc + image.width / image.height

                    ), 0)

                    //console.log(test)

                    let left = 0

                    group.forEach((image, j) => {

                        let width = image.width / image.height / test * (clientWidth - (group.length - 1) * spaceHorizontal);

                        if(mobile) width = clientWidth

                        const height = width / image.width * image.height
                        const url = ( width > 900 || height > 900 ) ? image.thumbnailUrlTall : image.thumbnailUrl
                        
                        /*
                        content.push(
                            <div key={image.ID} style={{
                                position: 'absolute',
                                width: `${width}px`,
                                height: `${height}px`,
                                left: `${left}px`,
                                top: `${top}px`,
                                background: `url(${image.path})`,
                                backgroundSize: 'contain'
                                
                            }}
                            onClick={() => history.push({...location, search: `?i=${image.ID}` })}
                            >

                            </div>
                        )
                        */

                        if(mobile) {
                            left = 0
                        }

                        const inPdf = pdf.findIndex(item => item.idImage === image.ID) > -1
                        console.log(inPdf)
                        console.log(inPdf)

                        content.push(
                            <Image 
                                key={image.ID}
                                style={
                                    {
                                        position: 'absolute',
                                        width: `${width}px`,
                                        height: `${height}px`,
                                        left: `${left}px`,
                                        top: `${top}px`,
                                    }
                                }
                                image={image.preview}
                                onClick={() => history.push({...location, search: `?i=${image.ID}` })}
                                onBookMark={() => onBookMark(image.ID)}
                                inPdf={inPdf}
                            />
                        )
                    

                        left += width + spaceHorizontal
                        
                        if(mobile) {
                            top += height + spaceVertical

                        } else if (j === group.length - 1) {
                            top += height

                            if (i < groups.length - 1) top += spaceVertical
                        }

                    })

                })

                height = top

                       
                return (<div className="list" style={{ height: `${height}px` }}>
                    {content}
                </div>)
            

        },
        [style, pdf]
    );

    return (
        <div ref={ref} style={{position: 'relative'}}>
            {html}
        </div>
    );
};

export default withRouter(Images);