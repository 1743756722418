import React from 'react';
import styles from './Home.module.sass'

import Slider from 'components/page/parts/slider'
import { useState, useEffect } from 'react';

import LazyImage from 'components/page/parts/lazyImage'

import Icon from 'components/Icon'

const Index = ({ data, style }) => {
    const [scrollTop, setScrollTop] = useState(0)
    const [ loadMore, setLoadMore ] = useState(false)
    const [ mute, setMute ] = useState(true)

    useEffect(() => {
        //console.log('header', ref.current)
        const scroll = () => {
            const scrollTop = window.scrollY
            setScrollTop(scrollTop)
        }

        window.addEventListener('scroll', scroll)

        return () => {
            window.removeEventListener('scroll', scroll)
        }

    }, [])

    const toggleMute = () => {

        var video=document.getElementById("video");

        video.muted = !video.muted;

        setMute(!mute)
    }

    console.log(data)

    return (
        <div className={styles.home}>

            <div className="header" style={{ transform: `translateY(${-scrollTop / 2}px)` }}>
                <video autoPlay muted loop playsInline id="video">
                    <source id="mp4" src="http://backend.mintartistmanagement.world/media/MINT_web_HD.mp4" type="video/mp4" />
                </video>
                
            </div>
            <div className="logo" style={{ transform: `translateY(${-scrollTop * 2}px)` }}>
            </div>
            <div className="content">
                <span className="mute" onClick={toggleMute}>
                    <Icon type={mute ? 'muted' : 'mute'} />
                </span>
                <div className="center">
                    <div className="left">
                        <Slider data={data.content.featured} style={style}></Slider>
                    </div>
                    <div className="right">
                        <div className={'instagram' + (loadMore ? ' more' : '')}>
                            <span className="border">
                                <h2 style={style.borderTitle}>Instagram</h2>
                            </span>
                            <div dangerouslySetInnerHTML={{ __html: data.content.instagram }} />
                            {!loadMore &&
                                <div className="more"> 
                                    <span className="action" onClick={() => setLoadMore(true)}>Load More</span>
                                </div>
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default Index;