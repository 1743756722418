import { put, takeEvery, delay, takeLatest, all, fork, call, select } from 'redux-saga/effects'
import { pageConstants } from '../constants';
import { fetchPage } from '../api';

export function* handlePageLoad(payload) {
    //console.log(payload)
    try {
        //yield console.log('ss')
        //const page = yield select(getPage);
        const page = yield call(fetchPage, payload.path);
        page.path = payload.path
        //yield put(setImages(images));
        yield put({ type: pageConstants.GETPAGE_SUCCESS, page })
    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }
}

export function* handleSetLoading(payload) {
    console.log('handleSetLoading')
    console.log(payload)
    try {
        yield put({ type: pageConstants.SETLOADING_SUCCESS, loading: payload.loading })
    } catch (error) {
        yield console.log('ss')
        //yield put(setError(error.toString()));
    }
}

export default function* watchPageLoad() {
    yield takeEvery(pageConstants.GETPAGE_REQUEST, handlePageLoad);
    yield takeEvery(pageConstants.SETLOADING_REQUEST, handleSetLoading)
}