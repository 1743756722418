import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link, NavLink } from 'react-router-dom';
import styles from './ModelList.module.sass'
import { addRemoveToCasting } from 'actions'

import moment from 'moment'
import { useState } from 'react';

import LazyImage from 'components/page/parts/lazyImage'




const Index = withRouter(({data, location, history, casting, addRemoveToCasting, style}) => {

    const [ filters, setFilters ] = useState({
        
    })
    const [ dimensions, setDimensions ] = useState({
        width: (style.contentWidth+30),
        columns: (style.contentWidth+30)>800 ? 3 : (style.contentWidth+30)>400 ? 2 : 1
    });

    const [ isCasting, setIsCasting ] = useState(location.pathname==='/casting/')

    
    
    let bookmarksNew = casting

    
    
    const [ bookmarks, setBookmarks ] = useState(bookmarksNew) 

    const ref = useRef(null)
    const skipAnimate = useRef(null)

    useEffect(() => {
        const hash = location.hash.replace(/#/, '')       
        const gender = !hash ? '' : hash==='women' ? 'woman' :  'man'
        let category = location.pathname.indexOf('/models/') > -1 ? 'models' : 'artists'

        if(location.pathname==='/models/' || location.pathname==='/artists/') {
            setFilters({
                category,
                gender
            })

            /*
            setTimeout(() => { 
                window.dispatchEvent(new Event('resize'));
            }, 500);               
            */ 
        }

        if(location.pathname==='/casting/') {
            
            let m = new URLSearchParams(location.search).get('m')

            if(m) {
            
                bookmarksNew = m.split(',').map(value => (parseInt(value)))
                setBookmarks(bookmarksNew)
            } else {
                setBookmarks(casting)
            }
        }

    }, [location, casting])

    useEffect(() => {
       

        if(location.pathname==='/models/' || location.pathname==='/artists/') {
            
            setBookmarks(casting)
        }

    }, [ casting])

    useEffect(() => {
        const width = (style.contentWidth+30)
        const columns = (style.contentWidth+30)>800 ? 3 : (style.contentWidth+30)>400 ? 2 : 1

        //console.log(columns + '/' + dimensions.columns)
        //if(columns===dimensions.columns) skipAnimate.current = true
        skipAnimate.current = true

        setDimensions({
            width,
            columns
        })        

        

    }, [style])

    /*
    const hash = location.hash.replace(/#/, '')
    const gender = !hash ? '' : hash==='women' ? 'woman' :  'man'
    */
    
    /* //// */

    const copyToClipboard = () => {

        let str = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/?m=' + casting.join(',')

        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    const mailCasting = () => {

        let link = window.location.href.slice(0, window.location.href.lastIndexOf('/')) + '/?m=' + casting.join(',')

        const body = `Dear Client,
we have composed an individual casting for you, please click on the following link to review or edit:

${link}

In case you have any questions please feel free to contact us. Meanwhile we are very looking forward to your feedback.

Yours sincerly
XXX
`;

        window.location.href = "mailto:&subject=Casting&body=" + encodeURIComponent(body);

    }

    const handleBookmark = (id) => {
        
            
        let m = new URLSearchParams(location.search).get('m')

        if(m) {
        
            bookmarksNew = m.split(',').map(value => (parseInt(value)))
            
            console.log(bookmarksNew)

            bookmarksNew = bookmarksNew.filter(bookmark => bookmark !== id)

            history.replace({...location, search: '?m=' + bookmarksNew.join(',')})


        } else {
            addRemoveToCasting(id)
        }
      
    }

    /* /////// */



    let html 
    let height = 10000

    console.log(data.content)
    console.log(isCasting)
    console.log(bookmarks)
    

    console.log('>> ' + dimensions.width)

       

        let teaserWidth = (dimensions.width)/dimensions.columns

       

        //console.log(teaserWidth)

        let visibleTotal 

        if(isCasting) {
            visibleTotal = casting.length
        } else {
            visibleTotal = data.content.filter(item => item.categories.findIndex(category => category === filters.category) > -1 && (item.gender === filters.gender || !filters.gender) ).length
        }

        let hiddenTotal = data.content.length - visibleTotal

        let visibleCount = 0
        let hiddenCount = visibleTotal

        console.log(visibleTotal, visibleCount, hiddenTotal, hiddenCount)

        console.log(casting)
        

        html = data.content.map((item, i) => {

            let visible = item.categories.findIndex(category => category === filters.category) > -1 && (item.gender === filters.gender || !filters.gender)

            const bookmarked = bookmarks.findIndex(bookmark => bookmark === item.ID)>-1

            if(isCasting) {
               
                if(bookmarked) {
                    visible = 1
                } else {
                    visible = 0
                }
                
            }

            let position = visible ? visibleCount : i

            //console.log()
            
            let xPos = position%dimensions.columns
            let yPos = Math.floor(position/dimensions.columns)
            


            console.log(position, visible, xPos, yPos)

            let style = {
                width: `${100/dimensions.columns}%`,
                transform: `translate(${xPos*teaserWidth}px, ${yPos*(teaserWidth+25)}px) scale(${visible ? 1 : 0})`,
                opacity: visible ? 1 : 0
               
            }

            if(visible) {
                visibleCount++
            } else {
                //hiddenCount++
            }

            return (
                <li key={item.ID} style={style}>
                    <div>
                        <Link 
                            className="clickArea"
                            to={`/${item.category}/${item.name}`} />
                        <div className="image">
                            {item.image &&
                                <LazyImage
                                    image={item.image.path}
                                    />
                            }
                        </div>
                        <span className={'casting' + (bookmarked ? ' active' : '')} onClick={() => handleBookmark(item.ID)}></span>
                        <h3>{item.title}</h3>
                    </div>
                </li>
            )
        })

        height = Math.ceil(visibleCount/dimensions.columns)*(teaserWidth+25)

    

    const classes = [styles.modelList, 'center']
    skipAnimate.current && classes.push('noanimate')

    skipAnimate.current = false

    return (
        <div className={classes.join(' ')}>
            <div className="left">
                <div>
                    {!isCasting &&
                        <>
                            <ul className="actions">
                                <li><NavLink to="/artists/">Artists</NavLink></li>
                                <li><NavLink to="/models/">Models</NavLink></li>
                            </ul>
                            <ul className="actions">
                                <li><NavLink to={{...location, hash: null}} isActive={() => location.hash === ''}>All</NavLink></li>
                                <li><NavLink to={{...location, hash: '#men'}} isActive={() => location.hash === '#men'}>Men</NavLink></li>
                                <li><NavLink to={{...location, hash: '#women'}} isActive={() => location.hash === '#women'}>Women</NavLink></li>
                            </ul>
                        </>
                    }
                    {isCasting &&
                        <ul className="actions">
                            <li><span onClick={() => copyToClipboard()}>Copy casting link</span></li>
                            <li><span onClick={() => mailCasting()}>Mail casting link</span></li>
                        </ul>
                    }
                    <div className="list">
                        <span className="border"><h1 style={style.borderTitle}>{isCasting ? 'casting' : 'talents'}</h1></span>
                        <ul ref={ref} style={{height: `${height}px`}}>
                            {html}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
});

const mapStateToProps = (state, props) => {
    const { measurements } = state.config
    let { casting } = state.bookmarks

	return {
        measurementsCountry: measurements,
        casting
	}
}

const mapDispatchToProps = dispatch => ({
    addRemoveToCasting: (id) => dispatch(addRemoveToCasting(id))
})

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Index))

